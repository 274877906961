@import "../../node_modules/react-toggle/style.css";

$main: #7161ef;
$secondary: #957fef;
$alt: #b79ced;
$backup: #dec0f1;
$light: #efd9ce;
$darkgray: #787878;
$gray-bg: #c4c4c4;
$black: rgb(33, 36, 41);

.toggle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  .react-toggle {
    vertical-align: middle;
  }
  span {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

// utilities

body {
  background-color: rgb(44, 47, 54);
  background-image: radial-gradient(
    50% 50%,
    rgba(33, 114, 229, 0.1) 0%,
    rgba(33, 36, 41, 0) 100%
  );
  background-position: 0px -30vh;
  min-height: 100vh;
}

body,
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

code {
  font-family: monospace;
  background-color: #eee;
  padding: 0.25rem;
  color: tomato;
  font-size: 1.2rem;
}

small.info {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}
a {
  text-decoration: none;
  transition: color 0.15s ease-in;
  color: $main;
}

.alert {
  color: $backup;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  background-color: $main;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  font-weight: 600;

  @media screen and (max-width: 45rem) {
    max-width: 280px;
  }
}

button,
.btn {
  background: $main;
  color: $light;
  border: 2px solid $main;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: $secondary;
  }

  &.alt {
    background-color: $secondary;
    color: $backup;
    border: 2px solid $secondary;

    &:hover {
      background-color: $alt;
      border: 2px solid $alt;
    }
  }

  &.blk {
    background-color: $black;
    color: $main;
    border: 2px solid $black;

    &:hover {
      background-color: $backup;
      color: $black;
    }
  }
}

input,
select,
textarea {
  display: block;
  background: transparent;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 0.5rem;
  border-radius: 1rem;
  border: 2px solid $main;
}

select {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.dropzone {
  padding: 2em;
  border: 2px dashed $main;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

// HEADER

.header {
  background-color: $secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);

  .left {
    h1 {
      color: $light;
    }
  }

  .right {
    display: flex;
    a {
      margin-right: 0.5rem;
      color: $light;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @media screen and (max-width: 45rem) {
    flex-direction: column;

    .left {
      margin-bottom: 0.5rem;
    }

    .right {
      a {
        font-size: 0.8rem;
      }
    }
  }
}

// LOGIN PAGE

.login {
  padding-top: 2rem;
  height: 100vh;

  .form-wrapper {
    width: 400px;
    margin-right: auto;
    margin-left: auto;
    background-color: $black;
    color: $light;
    border-radius: 1rem;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .label {
      color: $secondary;
      font-weight: 700;
    }

    input {
      color: $main;
    }

    @media screen and (max-width: 30rem) {
      width: 280px;
    }
  }
}

// HOME

.home {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .top-btns {
    max-width: 400px;
    margin-right: auto;
    margin-left: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button {
      margin-right: 0.5rem;
    }

    @media screen and (max-width: 45rem) {
      justify-content: center;
      flex-direction: column;
      margin-left: auto;
    }
  }

  h2 {
    color: $alt;
    font-weight: 600;
    text-transform: uppercase;
  }
}

// CLAIMS DASHBOARD - USER

.claims-container-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    color: $light;
  }
}

.claims-container {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-gap: 2em;
  justify-content: center;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, auto);
  }
  .item {
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $light;
    background-color: $main;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    p,
    h3,
    h4 {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }

    .bold {
      font-weight: 600;
    }

    button {
      margin-top: 1rem;
    }
  }
}

// CLAIMS DASHBOARD - ADMIN

.admin-claims {
  @extend .home;

  h2,
  h1 {
    color: $main;
    margin-bottom: 1rem;
  }

  .claims-container {
    .item {
      background-color: $black;
    }
  }

  .toggle {
    color: $main;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: block;
  }
}

// ADMIN VIEW CLAIM

.admin-view-claim {
  padding-top: 2rem;
  padding-bottom: 8rem;

  .top-section {
    width: 80%;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    background-color: $black;
    color: $main;
    border-radius: 1rem;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2,
    h3,
    h4 {
      margin-bottom: 0.5rem;
    }
  }

  .btns {
    grid-row-gap: 1.2rem;
    button {
      height: 55px !important;
    }

    .big-btn {
      grid-column: 1/4;
    }
  }
}

.admin-claim-items-table {
  table {
    max-width: 900px;
    text-align: center;
    padding-left: 1rem;
  }
}
// VIEW CLAIM

.view-claim {
  padding-top: 2rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.claim-top {
  background-color: $black;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  text-align: center;
  color: $main;

  p,
  h2,
  h3,
  h4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  @media screen and (max-width: 45rem) {
    max-width: 600px;
    width: 85%;
  }
}

table,
tr,
td,
th {
  padding: 1em;

  a {
    color: $light;
    text-decoration: underline;
  }
}

table {
  background-color: $main;
  color: $light;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.claim-details {
  background-color: $black;
  color: $main;
  border-radius: 1rem;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;

  .total {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 45rem) {
    max-width: 600px;
    width: 85%;
  }
}

.btns {
  margin-top: 2rem;
  margin-bottom: 5rem;
  padding-bottom: 2rem;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  align-items: center;

  button {
    height: 85px;
  }

  @media screen and (max-width: 45rem) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 0.5rem;

    .big-btn {
      grid-column: 1/3;
    }
  }
}

.admin-claim-items-table {
  table {
    margin-top: 1rem;
    margin-bottom: 4rem;
    width: 100%;

    thead,
    tbody {
      tr,
      th,
      td {
        text-align: left;
        padding-left: 0;

        &.warn {
          color: tomato;

          &:after {
            content: " 🛑";
          }
        }
      }
    }
  }
}
.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range {
  background-color: $main !important;
  padding: 0.5rem;
}

// ADMIN VIEW CLAIMS

.btns {
  button {
    margin-right: 0.5rem;
  }
}

.notes {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: $light;
  width: 70%;
  max-width: 800px;

  textarea {
    margin-right: auto;
    margin-left: auto;
    color: $light;
  }
}

.conversion-table {
  width: 70%;
  max-width: 900px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  color: $light;
  background-color: $secondary;

  h3 {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: 300;
  }

  .conversion-inner {
    margin-top: 2rem;
    .conversion-row {
      margin-top: 0.99rem;
      margin-bottom: 0.99rem;
      display: flex;
      width: 100%;

      &.head {
        span {
          font-weight: 500;
        }
      }

      span {
        width: 33%;
      }
    }
  }
}
// CLIENTS
.clients,
.categories {
  background-color: $black;
  color: $main;
  width: 500px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  h2 {
    margin-bottom: 1rem;
  }

  ul {
    margin-left: 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      margin-right: 1rem;
    }
  }
}

// NEW CLAIM PAGE

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__suggestions-container--open {
  background: $main;
  color: $black;
  border-radius: 1rem;
  position: absolute;
  margin-top: -1.2em;

  ul {
    list-style-type: none;
    li {
      word-wrap: break-word;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.new-claim-container {
  display: flex;
  width: 100%;
  padding-bottom: 8rem;
  justify-content: center;

  .toggle-claims {
    display: none;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;

    .toggle-claims {
      display: block;
      width: 130px;
      align-self: flex-start;
      font-size: 0.7rem;
      padding: 0.25rem;
      margin-top: 1rem;
      margin-left: 0.25rem;
    }
  }
}

.claim-table {
  max-width: 900px;
  width: 40%;
  padding: 2rem;
  background-color: $secondary;
  border-radius: 1rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  margin: 1rem;

  h3.empty-state {
    font-weight: 300;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    text-align: center;
  }

  .item {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin: 0.5rem;
    background-color: $black;
    color: $main;
    border-radius: 1rem;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .row1 {
      align-self: flex-start;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .row2 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .col3 {
        a {
          text-decoration: underline;
        }
      }
    }

    .identifier {
      background-color: $secondary;
      color: $black;
      max-width: 100px;
      text-align: center;
      padding: 0.5rem;
      border-radius: 1rem;
      font-weight: 700;
    }
  }

  .submit-btns {
    margin-bottom: 1rem;
    small {
      display: block;
      margin-top: 2em;
      max-width: 36em;
      line-height: 1.5;
      font-weight: 300;
      font-size: 0.65rem;
    }
  }

  @media screen and (max-width: 45rem) {
    max-width: unset;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;

    .item {
      font-size: 1rem;
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      span,
      h3,
      p {
        font-size: 1rem;
      }

      .row2 {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        text-align: left;
        grid-row-gap: 1rem;
      }
    }
  }
}

.DayPickerInput-Overlay {
  background: $main !important;
  color: $black;
  font-weight: 600;
  border-radius: 1rem;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
}

.DayPicker-Weekday {
  color: $light !important;
}

.DayPicker-Day--today {
  color: $backup !important;
}

.new-claim {
  background-color: $black;
  color: $light;
  max-width: 800px;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);

  input,
  select {
    color: $light;
  }

  h1 {
    margin-bottom: 2rem;
    color: $main;
  }

  .form {
    .form-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;

      input {
        max-width: 80%;
      }
    }
    .notes {
      margin-top: 2rem;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;

      .label {
        font-size: 1.25rem;
        font-weight: 300;
      }

      textarea {
        width: 60%;
        min-height: 150px;
      }
    }
  }

  .new-item-form {
    .form-inner {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-row-gap: 1rem;

      button {
        grid-row: 5;
      }
    }
  }
  .submit-btns {
    margin-top: 2rem;
    margin-bottom: 2rem;

    button {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 45rem) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .form {
      .form-top {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .new-item-form {
      .form-inner {
        margin-top: 1rem;
        grid-template-columns: repeat(1, auto);

        button {
          grid-row: unset;
        }
      }
    }
  }
}

// UPDATE CLAIM PAGE

.update-claim {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .claim-info {
    background-color: $black;
    color: $main;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      margin-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }

  h3 {
    padding-left: 0;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 4rem;
  }

  table {
    width: 80%;
    tr,
    th,
    td {
      text-align: left;
    }
  }

  .new-item-form {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    background-color: $alt;
    color: $black;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);

    h3 {
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: $main;
      text-align: center;
    }

    .form-inner {
      display: grid;
      grid-template-columns: repeat(3, 33%);
      grid-row-gap: 1.4rem;
      justify-content: center;
      align-items: center;

      .receipt {
        grid-column: 1/3;
      }

      button {
        grid-row: 4;
        grid-column: 2/3;
        margin-bottom: 2rem;
      }
    }

    @media screen and (max-width: 45rem) {
      .form-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .notes {
    margin-top: 2rem;
    margin-bottom: 8rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: $light;
      font-size: 1.2rem;
      font-weight: 800;
    }

    textarea {
      width: 40%;
      min-height: 150px;
      color: $light;
      line-height: 1.5;
      padding: 1rem;
    }
  }

  @media screen and (max-width: 45rem) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

// NEW EMPLOYEE PAGE

.new-user {
  padding-left: 4rem;
  padding-right: 4rem;

  h1 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  h4 {
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .form {
    input {
      width: 30%;
    }

    button {
      margin-bottom: 4rem;
    }
  }
}

// FIRST LOGIN PAGE

.first-login {
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    max-width: 30rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
  }
}

// BULK IMPORT PAGE

.bulk-import {
  padding-left: 4rem;
  padding-right: 4rem;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
    font-weight: 300;
  }

  input {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  button {
    margin-bottom: 4rem;
  }

  .users-table {
    margin-top: 4rem;
    h3 {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

// BANK DETAILS

.bank-details {
  .section {
    h3 {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      margin-top: 2rem;
    }

    p {
      margin-top: 2rem;
    }

    input {
      margin-bottom: 3.5rem;
    }
  }

  button {
    margin-top: 2.25rem;
    margin-bottom: 8rem;
  }
}

// ADMIN View Users

.user-list {
  padding-top: 2rem;
  .top-section {
    display: flex;
    padding: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    width: 400px;
    border-radius: 1rem;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    background-color: $backup;
    color: $secondary;
    align-items: center;
    justify-content: space-around;
  }
  .search-filter {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.4rem;
    padding: 1rem;
    color: $main;
    font-weight: 600;
  }

  h4 {
    margin-top: 2rem;
    font-weight: 300;
  }

  .user-container {
    margin-top: 2rem;
    padding-bottom: 8rem;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    .user-item {
      display: flex;
      width: 100%;
      padding: 0.5rem;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
      color: $main;
      background-color: $black;

      img {
        max-width: 5rem;
        border-radius: 999px;
        height: auto;
        align-self: center;
        margin-right: 1.5rem;

        &.user {
          border: 4px solid $main;
        }

        &.admin {
          border: 4px solid tomato;
        }
      }

      span {
        margin-right: 1.5rem;
        width: 20%;
        h4 {
          margin-top: 0;
          margin-bottom: 0.25rem;
        }
      }
      p {
        width: 20%;
        text-align: center;
      }
      a {
        margin-left: auto;
        margin-right: 1rem;
      }
    }
  }
}

// ADMIN VIEW USER

.admin-user-profile,
.user-profile {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  @extend .user-profile;

  .profile-top {
    justify-content: center;
    align-items: center;
  }

  .missing-details,
  .reset-password {
    width: 93%;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-height: 180px;
    border-radius: 1rem;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    background-color: $alt;
    color: $black;

    p {
      margin-bottom: 1rem;
      font-weight: 600;

      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }

  .bank-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding-bottom: 4rem;

    @media screen and (max-width: 45rem) {
      flex-direction: column;
    }

    h2 {
      text-align: center;
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: $main;
    }

    table {
      width: 80%;
      min-height: 445px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 2.5rem;

      tbody {
        tr {
          td {
            border: 1px solid #ccc;
            border-radius: 0.25rem;
            width: 50%;

            &:nth-child(1) {
              font-weight: 600;
            }
          }
        }
      }
    }

    h3,
    a {
      width: 80%;
      padding-left: 1rem;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 0.5rem;
    }
  }
}

.bank-details-form {
  color: $light;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 4rem;

  .section {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 2rem;

    input {
      color: $light;
    }

    @media screen and (max-width: 45rem) {
      grid-template-columns: repeat(1, auto);
    }
  }
}

// USER PROFILE PAGE

.user-profile {
  .profile-top {
    display: flex;
    background-color: $black;
    color: $main;
    margin-top: 1rem;
    border-radius: 1rem;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    .image-container {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;

      img {
        max-width: 20rem;
        border-radius: 999px;
        border: 4px solid $main;
        margin-bottom: 2rem;
      }
    }

    .info-container {
      margin-left: 2rem;
      h1 {
        text-transform: uppercase;
        margin-top: 3rem;
        letter-spacing: 0.1em;
        font-weight: 400;
      }

      h2,
      h3 {
        font-weight: 300;
        font-size: 1.5rem;
        vertical-align: middle;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    @media screen and (max-width: 45rem) {
      flex-direction: column;
    }
  }
}

// PDF PAGE

.pdf-viewer {
  width: 100%;
  iframe {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
    height: 100%;
    min-height: 900px;
  }
}

@import "./mobile.scss";
